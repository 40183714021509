<template>
  <div class="content-box">
    <div class="search-box">
      <el-input
        placeholder="请输入名称"
        suffix-icon="el-icon-search"
        style="width: 300px"
        v-model="page.keyWord"
        @input="search"
      ></el-input>
    </div>
    <el-table :data="list" v-loading="loading" @sort-change="sortChange">
      <el-table-column prop="serviceName" label="申请的服务名称" width="200"></el-table-column>
      <el-table-column prop="areaName" label="服务所属行政区划" width="150"></el-table-column>
      <el-table-column prop="applyTime" label="申请时间" sortable="custom" width="200"> </el-table-column>
      <el-table-column prop="status" label="状态" width="150" sortable="custom">
        <template #default="scope">
          <!-- 1待审批  2已批准 3驳回 -->
          <div class="status-box" v-if="scope.row.status === 1">
            <span class="status-lamp" style="background: #6a6aea"></span>
            <span style="color: #6a6aea">待审批</span>
          </div>
          <div class="status-box" v-if="scope.row.status === 2">
            <span class="status-lamp" style="background: #10cb10"></span>
            <span style="color: #10cb10">已批准</span>
          </div>
          <div class="status-box" v-if="scope.row.status === 3">
            <span class="status-lamp" style="background: #ef4040"></span>
            <span style="color: #ef4040">已驳回</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="approveTime" label="审批时间" width="200" sortable="custom">
        <template #default="scope">
          <div v-if="!scope.row.approveTime">-</div>
          <div v-else>{{ scope.row.approveTime }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="applyReason" label="申请信息"></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scope">
          <el-button v-if="scope.row.status === 1" type="text" size="small" @click="edit(scope.row)"> 编辑 </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-show="!loading && list.length > 0"
      background
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.totalCount"
      @size-change="handlePageSizeChange"
      @current-change="handlePageChange"
    />
    <apply-dialog ref="applyRef" :addStatus="false" @search="search" />
  </div>
</template>
<script>
import { reactive, ref } from 'vue';
import { postApplyList } from '@/api/cim';
import { getUser } from '@/shared/userinfo';
import { userInfo } from '@/layout/messageCenter/user-info';
import { getShowBool } from '@/utils/permission-show-module';
import ApplyDialog from '@/views/services-center/space/components/ApplyDialog.vue';
export default {
  name: 'Service',
  components: {
    ApplyDialog,
  },
  setup() {
    const applyRef = ref();
    const list = ref([]);
    const loading = ref(true);
    const page = reactive({
      pageSize: 10,
      pageNo: 1,
      totalCount: 0,
      keyWord: '',
    });
    const sortType = reactive({
      sortType: '',
      sortField: '',
    });
    const getList = async () => {
      loading.value = true;
      if (!userInfo.value) {
        await getUser();
      }
      const { data } = await postApplyList({
        applyListCondition: {
          serviceName: page.keyWord,
          areaName: page.keyWord,
          employer: page.keyWord,
          applyUserName: page.keyWord,
          applyUserId: userInfo.value.userId,
        },
        pageNo: page.pageNo,
        pageSize: page.pageSize,
        ...sortType,
      });
      list.value = data.spaceServiceApprovals;
      page.totalCount = data.count;
      loading.value = false;
    };
    getList();
    const handlePageSizeChange = (pageSize) => {
      page.pageSize = pageSize;
      getList();
    };

    const handlePageChange = (pageIndex) => {
      page.pageNo = pageIndex;
      getList();
    };
    const search = () => {
      page.pageNo = 1;
      getList();
    };
    const sortChange = (sort) => {
      if (sort.prop) {
        console.log('排序变化', sort.prop, sort.order === 'ascending' ? '升序' : '降序');
        sortType.sortType = sort.order === 'ascending' ? 1 : 2;
        sortType.sortField = sort.prop;
      } else {
        console.log('取消排序');
        sortType.sortType = '';
        sortType.sortField = '';
      }
      getList();
    };
    const edit = (row) => {
      console.log('编辑', row);
      applyRef.value.handleOpen({
        approvalId: row.id,
        reason: row.applyReason,
      });
    };
    return {
      loading,
      applyRef,
      page,
      list,
      edit,
      search,
      sortChange,
      handlePageSizeChange,
      handlePageChange,
      getShowBool,
    };
  },
};
</script>
<style lang="scss" scoped>
.user-center-item {
  height: 100%;
  width: 100%;
}
.search-box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
  .el-input {
    margin-right: 20px;
  }
}
::v-deep .el-pagination {
  width: 100%;
  justify-content: flex-end;
  padding: 8px;
}
::v-deep .el-pagination__total {
  margin-right: auto;
}
::v-deep .el-icon {
  display: inline-block !important;
}
.status-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .status-lamp {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;
  }
}
.content-box {
  padding: 20px;
}
</style>
