<template>
  <el-dialog title="申请授权" v-model="visible" @close="handleClose" width="400px">
    <div class="main">
      <el-input type="textarea" v-model="applyReason" maxlength="255" placeholder="请输入申请理由"></el-input>
    </div>
    <div class="footer">
      <div class="footer-button">
        <el-button @click="handleClose">取消</el-button>
        <div class="save-btn-box" v-loading="loading">
          <el-button type="primary" @click="handleApply">确定</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, ref } from 'vue';
import { getUser } from '@/shared/userinfo';
import { userInfo } from '@/layout/messageCenter/user-info';
import { postApply, postUpdateApplyDetail } from '@/api/cim';
import { ElMessage } from 'element-plus';
export default {
  name: 'ApplyDialog',
  props: {
    // 判断是申请时的弹框，还是【我的申请】中编辑弹框
    addStatus: Boolean,
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const applyReason = ref('');
    const loading = ref(false);
    const serviceId = ref('');
    const updateParam = reactive({
      approvalId: '',
      reason: '',
    });
    getUser();
    const apply = async () => {
      if (applyReason.value === '') {
        ElMessage({
          message: '请填写申请理由',
          type: 'warning',
        });
        return;
      }
      loading.value = true;
      if (props.addStatus) {
        const { data } = await postApply({
          serviceId: serviceId.value,
          applyReason: applyReason.value,
          applyUserId: userInfo.value.userId,
          applyUserName: userInfo.value.userName,
        });
        console.log('请求结果', data);
        emit('getIdentifyButtonStatus');
      } else {
        updateParam.reason = applyReason.value;
        const { data } = await postUpdateApplyDetail({ ...updateParam });
        console.log('请求结果', data);
        emit('search');
      }
      loading.value = false;
      visible.value = false;
    };
    const handleClose = () => {
      visible.value = false;
      loading.value = false;
      applyReason.value = '';
    };
    const handleOpen = (data) => {
      console.log('打开弹框', data);
      visible.value = true;
      if (props.addStatus) {
        serviceId.value = data.serviceId;
      } else {
        updateParam.approvalId = data.approvalId;
        updateParam.reason = data.reason;
        applyReason.value = updateParam.reason;
      }
    };
    const handleApply = () => {
      apply();
    };
    return {
      visible,
      loading,
      applyReason,
      handleOpen,
      handleClose,
      handleApply,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.save-btn-box {
  display: inline-block;
  margin-left: 10px;
  ::v-deep .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
}
</style>
